@import url("https://fonts.googleapis.com/css?family=Noto+Sans|Noto+Sans+KR&display=swap");

body {
  margin: 0;
  /* overflow: hidden; */
  font-family: "BB Tree", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: inherit;
}
