@font-face {
    font-family: "BB Tree";
    src: url("./fonts/123RF_kkueez_B_TTF.ttf") format("truetype");
}

#animation_container,
#animation_container2,
#animation_container3,
#animation_container4 {
    align-self: center !important;
    transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
}

.fp-tableCell {
    overflow: hidden;
}

#section1 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.landing-fullpage img {
    text-align: center;
    margin: 0 auto;
}

.img-lg {
    display: inline;
}

.img-sm {
    display: none;
    background-size: 100% auto;
}

@media (max-width: 992px) {
    .img-lg {
        display: none;
    }

    .img-sm {
        display: inline;
    }
}
